import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/lj/workspace/bold/site/src/components/mdx/MDXPageLayout";
import { Demo } from '../../../components/Demo';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "date-picker",
      "style": {
        "position": "relative"
      }
    }}>{`Date picker`}</h1>
    <p>{`Date components allow the user to make a specific selection through a visual calendar. By default, the calendar always displays the current month or year. If you already have a date entered, the calendar displays the month or year for that date.`}</p>
    <h2 {...{
      "id": "best-practices",
      "style": {
        "position": "relative"
      }
    }}>{`Best practices`}</h2>
    <ul>
      <li parentName="ul">{`The field must allow the user to also enter the value through keyboard input.`}</li>
      <li parentName="ul">{`The field should have only one input type, and should not be divided into fields for each information (day, month, year).`}</li>
    </ul>
    <h3 {...{
      "id": "date-range-picker",
      "style": {
        "position": "relative"
      }
    }}>{`Date Range Picker`}</h3>
    <ul>
      <li parentName="ul">{`Use when the date selected is close to the actual date or for a period of less than 1 year.`}</li>
      <li parentName="ul">{`Use placeholders to indicate the format of the data entry, for example: dd/mm/yyyy or mm/dd/yy, etc..`}</li>
    </ul>
    <h3 {...{
      "id": "week-range-picker",
      "style": {
        "position": "relative"
      }
    }}>{`Week Range Picker`}</h3>
    <ul>
      <li parentName="ul">{`Use if the selected periods must start on a Sunday and end on a Saturday.`}</li>
      <li parentName="ul">{`If this component receives a date range in it's value prop, it must be a week, for example: 05/02/2021 (Sunday) to 05/08/2021 (Saturday). Otherwise, it will not be selected. An example of a invalid entry: 05/03/2021 (Monday) to 05/05/2021 (Wednesday).`}</li>
    </ul>
    <h3 {...{
      "id": "month-range-picker",
      "style": {
        "position": "relative"
      }
    }}>{`Month Range Picker`}</h3>
    <ul>
      <li parentName="ul">{`Use when the selected period must be at least an entire month.`}</li>
      <li parentName="ul">{`If the user should select a period with greater granularity, such as weeks or days, use the above components.`}</li>
    </ul>
    <h2 {...{
      "id": "date-picker-types",
      "style": {
        "position": "relative"
      }
    }}>{`Date picker types`}</h2>
    <table>
  <tbody>
    <tr>
      <th width={160}>Default</th>
      <td>When it's necessary to select one date.</td>
    </tr>
    <tr>
      <th>Simple Date Picker</th>
      <td>
        Recommended when the user knows the date and does not need the calendar to anticipate the dates. Usually used on
        credit card expiration dates.
      </td>
    </tr>
    <tr>
      <th>Range Date Picker</th>
      <td>Used to select a date range.</td>
    </tr>
    <tr>
      <th>Range Week Picker</th>
      <td>Used to select a week range.</td>
    </tr>
    <tr>
      <th>Range Month Picker</th>
      <td>Used to select a month range.</td>
    </tr>
  </tbody>
    </table>
    <h3 {...{
      "id": "default",
      "style": {
        "position": "relative"
      }
    }}>{`Default`}</h3>
    <Demo src='pages/components/date-picker/DatePickerExample' mdxType="Demo" />
    <h3 {...{
      "id": "date-range-picker-1",
      "style": {
        "position": "relative"
      }
    }}>{`Date Range Picker`}</h3>
    <Demo src='pages/components/date-picker/DateRangePickerExample' mdxType="Demo" />
    <h3 {...{
      "id": "week-range-picker-1",
      "style": {
        "position": "relative"
      }
    }}>{`Week Range Picker`}</h3>
    <Demo src='pages/components/date-picker/WeekRangePickerExample' mdxType="Demo" />
    <h3 {...{
      "id": "month-range-picker-1",
      "style": {
        "position": "relative"
      }
    }}>{`Month Range Picker`}</h3>
    <Demo src='pages/components/date-picker/MonthRangePickerDefault' mdxType="Demo" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      